import Vue from 'vue'
import Vuex from 'vuex'

import profile from '@/store/modules/profile'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: config.rails_env !== 'production',

  actions: {
    init({ dispatch }) {
      dispatch('profile/init', { root: true })
    }
  },

  modules: {
    profile
  }
})

export default store
