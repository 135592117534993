
<transition appear :name="transitionName">
  <div v-if="isOpen" class="Cookie" :class="[containerPosition, cookieTheme]">
    <slot :accept="accept" :close="close" :decline="decline" :open="open">
      <div class="Cookie__content">
        <slot name="message">{{ message }}</slot>
      </div>
      <div class="Cookie__buttons">
        <a v-if="externalButtonLink" :class="buttonClass" :href="buttonLink" :target="target">{{ buttonLinkText }}</a>
        <router-link v-if="internalButtonLink" :class="buttonClass" :to="buttonLink">{{ buttonLinkText }}</router-link>
        <button v-if="buttonDecline" :class="buttonDeclineClass" @click="decline">{{ buttonDeclineText }}</button>
        <button :class="buttonClass" @click="accept">{{ buttonText }}</button>
      </div>
    </slot>
  </div>
</transition>
