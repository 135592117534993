
<svg
  class="feather feather-lock"
  fill="none"
  stroke="currentColor"
  stroke-linecap="round"
  stroke-linejoin="round"
  stroke-width="2"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect height="11" rx="2" ry="2" width="18" x="3" y="11"></rect>
  <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
</svg>
