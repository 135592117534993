// JsFromRoutes CacheKey 26ee658f4082a6bd53cfafdcc31a80df
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/api/v1/events'),
  create: definePathHelper('post', '/api/v1/events'),
  get: definePathHelper('get', '/api/v1/events/:id'),
  update: definePathHelper('patch', '/api/v1/events/:id'),
  destroy: definePathHelper('delete', '/api/v1/events/:id'),
}
