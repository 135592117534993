
<component
  :is="tagName"
  class="Button"
  :class="{
    'isPrimary': primary,
    'isSecondary': secondary,
    'isOutline': outline,
    'isDanger': danger,
    'isLarge': large,
    'isSmall': small,
    'isExtraSmall': xsmall,
    'isExtraExtraSmall': xxsmall
  }"
  v-bind="$attrs"
  v-on="$listeners"
>
  <slot />
</component>
