<template>
  <div class="TextAreaInput">
    <textarea
      class="placeholder-gray-400 block mb-px w-full border-gray-300 focus:border-primary-500 rounded-md shadow-sm focus:ring-primary-500 sm:text-sm"
      :class="{ 'isInvalid': error }"
      :value="value"
      v-bind="$attrs"
      v-on="listeners"
    ></textarea>
    <InputErrorMessage class="mt-2" :error="error" />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: String,
      required: false
    },

    error: {
      type: Array | String,
      required: false
    }
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (e) => this.$emit('input', e.target.value)
      }
    }
  }
}
</script>
<style scoped lang="postcss">
.TextAreaInput {
  :disabled {
    @apply bg-gray-100 cursor-not-allowed;
  }
}
</style>

