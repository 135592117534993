// JsFromRoutes CacheKey 3688dc6f02d7deb11b530251840b671f
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  publicShow: definePathHelper('get', '/api/v1/theses/public/:id'),
  listAll: definePathHelper('get', '/api/v1/theses/all'),
  list: definePathHelper('get', '/api/v1/theses'),
  create: definePathHelper('post', '/api/v1/theses'),
  get: definePathHelper('get', '/api/v1/theses/:id'),
  update: definePathHelper('patch', '/api/v1/theses/:id'),
  destroy: definePathHelper('delete', '/api/v1/theses/:id'),
}
