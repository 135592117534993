// JsFromRoutes CacheKey b5364fa567a1857eeceebe08b4a62863
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/api/v1/users'),
  publicList: definePathHelper('get', '/api/v1/users/all'),
  search: definePathHelper('get', '/api/v1/users/search/:q'),
  csvTemplate: definePathHelper('get', '/api/v1/users/csv-template'),
  get: definePathHelper('get', '/api/v1/users/:id'),
  publicProfile: definePathHelper('get', '/api/v1/users/profile/:id'),
  destroy: definePathHelper('delete', '/api/v1/users/:id'),
  create: definePathHelper('post', '/api/v1/users/create'),
  update: definePathHelper('post', '/api/v1/users/update/:id'),
  updateProfile: definePathHelper('post', '/api/v1/users/update-profile'),
  signOut: definePathHelper('post', '/api/v1/users/sign-out'),
  bulkImport: definePathHelper('post', '/api/v1/users/bulk-import'),
  processCsv: definePathHelper('post', '/api/v1/users/process-csv'),
  signInAs: definePathHelper('post', '/api/v1/users/sign-in-as/:id'),
}
