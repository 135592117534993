import AppButton from './AppButton.vue'
import Avatar from './Avatar.vue'
import Breadcrumbs from './Breadcrumbs.vue'
import Checkbox from './Checkbox.vue'
import Dropdown from './Dropdown.vue'
import Icon from './Icon.vue'
import InputErrorMessage from './InputErrorMessage.vue'
import Radiobox from './Radiobox.vue'
import SelectInput from './SelectInput.vue'
import TextAreaInput from './TextAreaInput.vue'
import TextInput from './TextInput.vue'

export default {
  AppButton,
  Avatar,
  Breadcrumbs,
  Checkbox,
  Dropdown,
  Icon,
  InputErrorMessage,
  Radiobox,
  SelectInput,
  TextInput,
  TextAreaInput
}
