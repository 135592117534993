import qs from 'qs'
import Vue from 'vue'
import VueRouter from 'vue-router'

import AnalyticsService from '~/services/AnalyticsService'
import store from '~/store'

import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,

  mode: 'history',

  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (to.path === from.path) {
        resolve()
      } else if (savedPosition) {
        setTimeout(() => {
          resolve(savedPosition)
        }, 160)
      } else {
        resolve({ x: 0, y: 0 })
      }
    })
  },

  parseQuery(query) {
    return qs.parse(query)
  },
  stringifyQuery(query) {
    const result = qs.stringify(query)

    return result ? '?' + result : ''
  }
})

// Auth middleware
router.beforeEach((to, from, next) => {
  AnalyticsService.resetDatalayer()

  if (to.matched.some((record) => record.meta.requiresAdminAccess) && !store.state.profile.user.admin) {
    next({ name: 'dashboard' })
  } else {
    next()
  }
})

// Virtual pageview tracking
router.afterEach((to) => {
  if (to.path.indexOf('/app') === 0) {
    document.body.classList.remove('overflow-y-scroll')
  } else {
    document.body.classList.add('overflow-y-scroll')
  }

  // Send custom pageview event to Google tag manager
  setTimeout(() => {
    AnalyticsService.trackPageView(to.fullPath)
  }, 600)
})

export default router
