import EMAIL_REGEXP from '~/constants/emailRegexp'
import INSTAGRAM_REGEXP from '~/constants/instagramRegexp'
import URL_REGEXP from '~/constants/URLRegexp'

const ValidationService = {
  _validateAttrValue(data, attr, validator) {
    const value = data[attr]

    if (typeof validator !== 'function') {
      return undefined
    }

    return new Promise((resolve) => {
      validator({ ...validator, value, data, resolve })
    })
  },

  async validate(data, validators) {
    const promises = Object.entries(validators).reduce((promises, [attr, validators]) => {
      const promise = Promise.all(validators.map((validator) => this._validateAttrValue(data, attr, validator))).then((results) => {
        const errors = results.filter(Boolean)
        return errors.length ? { attr, errors } : undefined
      })

      return promises.concat([promise])
    }, [])

    const errors = (await Promise.all(promises)).filter(Boolean).reduce((errorsByAttr, error) => ({ ...errorsByAttr, [error.attr]: error.errors.shift() }), {})

    return {
      isValid: !Object.keys(errors).length,
      errors
    }
  }
}

export default ValidationService

// Validators

export const isRequired = (message) => {
  return ({ value, resolve }) => {
    !value && value !== 0 ? resolve(message || 'This field is required') : resolve()
  }
}

export const isValidEmail = () => {
  return async ({ value, resolve }) => {
    EMAIL_REGEXP.test(value) ? resolve() : resolve({ email: 'Please enter valid email' })
  }
}

export const isValidInstagramUsername = (message) => {
  return async ({ value, resolve }) => {
    INSTAGRAM_REGEXP.test(value) ? resolve() : resolve(message || 'Please enter valid instagram username')
  }
}

export const isValidURL = (message) => {
  return async ({ value, resolve }) => {
    !value || URL_REGEXP.test(value) ? resolve() : resolve(message || 'Please enter valid URL')
  }
}

export const isMin = (min, message) => {
  return async ({ value, resolve }) => {
    Number(value) < min ? resolve(message || 'Value is too low') : resolve()
  }
}

export const isMax = (max, message) => {
  return async ({ value, resolve }) => {
    Number(value) > max ? resolve(message || 'Value is too high') : resolve()
  }
}

export const maxLength = (max, message) => {
  return async ({ value, resolve }) => {
    String(value).length > max ? resolve(message || `Please enter maximum of ${max} characters`) : resolve()
  }
}

export const minLength = (min, message) => {
  return async ({ value, resolve }) => {
    String(value).length < min ? resolve(message || `Please enter at least ${min} characters`) : resolve()
  }
}
