<template>
  <div v-if="breadcrumbs.length">
    <sl-breadcrumb>
      <sl-breadcrumb-item v-for="(breadcrumb, index) in breadcrumbs" :key="index">
        <router-link v-if="breadcrumb.to || index !== length - 1" class="text-gray-500 hover:text-gray-700 text-sm font-medium" :to="breadcrumb.to">
          {{ breadcrumb.name }}
        </router-link>
        <div v-else class="text-gray-500 text-sm font-medium">
          {{ breadcrumb.name }}
        </div>
      </sl-breadcrumb-item>
    </sl-breadcrumb>
  </div>
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      required: false,
      default: []
    }
  },

  computed: {
    length() {
      return this.breadcrumbs.length || 0
    },

    backLink() {
      return length && this.breadcrumbs[this.length - 2]
    }
  }
}
</script>
