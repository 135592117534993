
<svg height="27px" version="1.1" viewBox="0 0 26 27" width="26px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Page-1" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1">
    <g id="b3d0bcfc-c2ba-41c8-b777-0600ff924e0b" fill="currentColor" fill-rule="nonzero">
      <path
        id="Path"
        d="M22.5253456,-1.77635684e-15 L3.47465438,-1.77635684e-15 C1.55760369,-1.77635684e-15 0,1.55760369 0,3.59447005 L0,22.764977 C0,24.8018433 1.55760369,26.359447 3.47465438,26.359447 L12.9400922,26.359447 L12.9400922,16.8940092 L10.5437788,16.8940092 C10.1843318,16.8940092 9.9447005,16.6543779 9.9447005,16.4147465 L9.9447005,13.2995392 C9.9447005,13.0599078 10.1843318,12.7004608 10.4239631,12.7004608 L12.9400922,12.7004608 L12.9400922,9.8248848 C12.9400922,6.47004608 14.9769585,4.55299539 17.9723502,4.55299539 L20.3686636,4.55299539 C20.7281106,4.55299539 20.9677419,4.79262673 20.9677419,5.15207373 L20.9677419,7.6682028 C20.9677419,8.0276498 20.7281106,8.2672811 20.3686636,8.2672811 L18.9308756,8.2672811 C17.2534562,8.2672811 16.8940092,9.1059908 16.8940092,10.1843318 L16.8940092,12.7004608 L20.4884793,12.7004608 C20.8479263,12.7004608 21.0875576,13.0599078 21.0875576,13.4193548 L20.7281106,16.4147465 C20.7281106,16.7741935 20.4884793,16.8940092 20.1290323,16.8940092 L17.0138249,16.8940092 L16.8940092,26.359447 L22.5253456,26.359447 C24.4423963,26.359447 26,24.8018433 26,22.764977 L26,3.59447005 C26,1.55760369 24.4423963,-1.77635684e-15 22.5253456,-1.77635684e-15 Z"
      ></path>
    </g>
  </g>
</svg>
