// JsFromRoutes CacheKey 54bfeb583a2ab015021f8f4384f423d7
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/api/v1/artwork_types'),
  create: definePathHelper('post', '/api/v1/artwork_types'),
  get: definePathHelper('get', '/api/v1/artwork_types/:id'),
  update: definePathHelper('patch', '/api/v1/artwork_types/:id'),
  destroy: definePathHelper('delete', '/api/v1/artwork_types/:id'),
}
