import api from '~/api'
import ValidationService, { isRequired, isValidInstagramUsername, isValidURL } from '~/services/ValidationService'

const validate = (resource) => {
  return new Promise((resolve, reject) => {
    const validationRules = {
      first_name: [isRequired('Zadajte meno')],
      last_name: [isRequired('Zadajte priezvisko')],
      department_id: [isRequired('Vyberte katedru')],
      country: [isRequired('Zadajte krajinu')],
      website: [isValidURL('Zadajte správny formát webovej adresy (napr. https://www.aku.sk)')],
      instagram: [isValidInstagramUsername('Zadajte správne instagramové meno')]
    }

    ValidationService.validate(resource, validationRules).then((result) => {
      result.isValid ? resolve() : reject(result.errors)
    })
  })
}

export default {
  signOut() {
    return api.users.signOut({})
  },

  createSignInLink(email) {
    const data = {
      email: email
    }

    return api.magicLinks.create({ data })
  },

  validateSignInLink(token) {
    const data = {
      token: token
    }

    return api.magicLinks.validate({ data })
  },

  async updateProfile(data) {
    return new Promise(async (resolve, reject) => {
      try {
        await validate(data)
        const resource = await api.users.updateProfile({ data: { user: data } })
        resolve(resource)
      } catch (e) {
        reject(e?.response?.data || e)
      }
    })
  }
}
