import Vue from 'vue'

import date from './date'
import relativeTime from './relativeTime'

const filters = {
  date,
  relativeTime
}

Object.entries(filters).forEach(([name, method]) => {
  Vue.filter(name, method)
})

export default filters
