// JsFromRoutes CacheKey a796e07d81abb11bb94e10d09d0f36d2
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  findBySlug: definePathHelper('get', '/api/v1/faculties/find-by-slug/:slug'),
  list: definePathHelper('get', '/api/v1/faculties'),
  create: definePathHelper('post', '/api/v1/faculties'),
  get: definePathHelper('get', '/api/v1/faculties/:id'),
  update: definePathHelper('patch', '/api/v1/faculties/:id'),
  destroy: definePathHelper('delete', '/api/v1/faculties/:id'),
}
