// JsFromRoutes CacheKey 6f309f8e7a83b1cdd4f5d312bdbcd4af
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/api/v1/courses'),
  create: definePathHelper('post', '/api/v1/courses'),
  get: definePathHelper('get', '/api/v1/courses/:id'),
  update: definePathHelper('patch', '/api/v1/courses/:id'),
  destroy: definePathHelper('delete', '/api/v1/courses/:id'),
}
