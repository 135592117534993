<template>
  <component
    :is="tagName"
    class="Button"
    :class="{
      'isPrimary': primary,
      'isSecondary': secondary,
      'isOutline': outline,
      'isDanger': danger,
      'isLarge': large,
      'isSmall': small,
      'isExtraSmall': xsmall,
      'isExtraExtraSmall': xxsmall
    }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    large: {
      type: Boolean
    },

    small: {
      type: Boolean
    },

    xsmall: {
      type: Boolean
    },

    xxsmall: {
      type: Boolean
    },

    primary: {
      type: Boolean
    },

    secondary: {
      type: Boolean
    },

    outline: {
      type: Boolean
    },

    danger: {
      type: Boolean
    }
  },

  computed: {
    tagName() {
      return this.$attrs.href ? 'a' : 'button'
    }
  }
}
</script>

<style scoped>
.Button {
  @apply flex justify-center px-4 py-2 text-white whitespace-nowrap text-base font-medium bg-gray-900 border border-transparent rounded-md shadow-sm;

  &:hover {
    @apply bg-black;
  }

  &:focus {
    @apply outline-none ring-primary-500 ring-offset-2 ring-2;
  }

  &:disabled {
    @apply opacity-50 pointer-events-none;
    &:focus {
      @apply shadow-none;
    }
  }

  &.isSecondary {
    @apply text-gray-900 bg-white border-gray-900;
    &:hover {
      @apply text-black bg-white border-black;
    }
  }

  &.isOutline {
    @apply text-primary bg-transparent border-primary;
    &:hover {
      @apply text-primary bg-transparent border-primary;
    }
  }

  &.isDanger {
    @apply text-white bg-red-500 border-red-500;
    &:hover {
      @apply text-white bg-red-600 border-red-600;
    }
  }

  &.isLarge {
    @apply text-lg;
  }

  &.isSmall {
    @apply text-sm;
  }

  &.isExtraSmall {
    @apply px-2 py-1 text-sm rounded;
  }

  &.isExtraExtraSmall {
    @apply px-2 py-1 text-xs;
  }
}
</style>
