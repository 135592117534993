// JsFromRoutes CacheKey 24cf9e0e7462c7d8e60a1e452dad34d5
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  findBySlug: definePathHelper('get', '/api/v1/departments/find-by-slug/:slug'),
  list: definePathHelper('get', '/api/v1/departments'),
  create: definePathHelper('post', '/api/v1/departments'),
  get: definePathHelper('get', '/api/v1/departments/:id'),
  update: definePathHelper('patch', '/api/v1/departments/:id'),
  destroy: definePathHelper('delete', '/api/v1/departments/:id'),
}
