<template>
  <div class="SelectInput">
    <select
      class="placeholder-gray-400 block w-full border-gray-300 focus:border-primary-500 rounded-md shadow-sm focus:ring-primary-500 sm:text-sm"
      :class="{ 'isInvalid': error }"
      v-bind="$attrs"
      v-on="listeners"
    >
      <option v-if="placeholder" disabled selected :value="undefined">
        {{ placeholder }}
      </option>

      <option
        v-for="option in options"
        :key="valueKey === undefined ? option : option[valueKey].toString()"
        :value="valueKey === undefined ? option : option[valueKey]"
      >
        {{ labelKey === undefined ? option : option[labelKey] }}
      </option>
    </select>

    <InputErrorMessage class="mt-2" :error="error" />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    options: {
      type: Array
    },

    labelKey: {
      type: [String, Number]
    },

    valueKey: {
      type: [String, Number]
    },

    placeholder: {
      type: String
    },

    error: {
      type: String
    }
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        change: (e) => this.$emit('input', e.target.value),
        input: (e) => this.$emit('input', e.target.value)
      }
    }
  }
}
</script>
<style scoped lang="postcss">
.SelectInput {
  :disabled {
    @apply bg-gray-100 cursor-not-allowed;
  }
}
</style>
