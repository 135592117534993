import config from '@/config'
import ProfileService from '@/services/ProfileService'
import StorageService from '@/services/StorageService'

const PROFILE_STORAGE_KEY = 'profile'

const types = {
  APP_LOADING_SET: 'APP_LOADING_SET',
  USER_SET: 'USER_SET'
}

const defaultState = () => ({
  user: config.user
})

const persistedState = () => {
  return StorageService.load(PROFILE_STORAGE_KEY, {})
}

const getInitialState = () => ({
  ...defaultState(),
  ...persistedState(),
  isLoading: false,
  errors: {}
})

const state = getInitialState()

const actions = {
  async init({ commit }) {
    try {
      // const user = await ProfileService.getUser()
      // commit(types.USER_SET, { user })
    } catch (e) {
      // sentry.captureException(e)
    }
  },

  async signOut({ commit }) {
    try {
      const result = await ProfileService.signOut()
      commit(types.USER_SET, { user: undefined })
      StorageService.remove(PROFILE_STORAGE_KEY)
      return result
    } catch (e) {
      // sentry.captureException(e)
    }
  },

  setAppLoading({ commit }, value) {
    commit(types.APP_LOADING_SET, value)
  },

  persist({ state }) {
    try {
      const persistedState = {}
      StorageService.persist(PROFILE_STORAGE_KEY, persistedState)
    } catch (e) {}
  }
}

const mutations = {
  [types.APP_LOADING_SET](state, isLoading) {
    state.isLoading = isLoading
  },

  [types.USER_SET](state, { user }) {
    state.user = user
  }
}

const getters = {
  isLoading(state) {
    return state.isLoading
  },

  user(state) {
    return state.user
  },

  errors(state) {
    return state.errors
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
