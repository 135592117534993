
<div
  class="Avatar flex flex-shrink-0 items-center justify-center text-center text-xs overflow-hidden"
  :class="{ 'w-16 h-16 md:w-24 md:h-24 text-2xl': large, 'w-6 h-6': small, 'w-8 h-8': !small && !large }"
  :style="{ backgroundColor }"
>
  <svg height="0" width="0">
    <clipPath id="avatar" clipPathUnits="objectBoundingBox">
      <path
        d="M0,0.5 C0,0.166666667 0.166666667,0 0.5,0 C0.833333333,0 1,0.166666667 1,0.5 C1,0.833333333 0.833333333,1 0.5,1 C0.166666667,1 0,0.833333333 0,0.5"
      ></path>
      <!-- <path
        d="M0.07,0.205 C0.018,0.283,0.001,0.359,0.001,0.432 C0.001,0.506,0.025,0.592,0.059,0.687 C0.084,0.757,0.125,0.853,0.163,0.886 C0.295,0.999,0.409,1,0.617,1 C0.76,1,0.857,0.945,0.898,0.886 C0.931,0.838,0.964,0.746,0.984,0.651 C1,0.547,1,0.471,1,0.432 C1,0.386,1,0.331,0.926,0.205 C0.885,0.134,0.819,0.106,0.697,0.064 C0.574,0.022,0.483,-0.026,0.333,0.016 C0.184,0.059,0.18,0.039,0.07,0.205"
      ></path> -->
    </clipPath>
  </svg>
  <img v-if="url" :alt="label" class="w-full h-full object-cover" :src="url" />
  <span v-else class="text-black font-bold opacity-50">{{ initials }}</span>
</div>
