import Vue from 'vue'

Vue.config.ignoredElements = [/^sl-/]

// Global components
import globalComponents from '@/components/global'
import filters from '@/filters'
import router from '@/router'
import store from '@/store'

Object.entries(globalComponents).forEach(([name, component]) => {
  Vue.component(name, component)
})

// Init App
import { Config } from '@js-from-routes/client'

import App from '@/App.vue'

const noop = (val) => val
Config.deserializeData = noop
Config.serializeData = noop

import InstantSearch from 'vue-instantsearch'
Vue.use(InstantSearch)

// VueObserveVisibility
import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

// a11y skip to content links
import VueSkipTo from '@vue-a11y/skip-to'
Vue.use(VueSkipTo)

// Shoelace
import '@shoelace-style/shoelace/dist/themes/light.css'
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js'
import '@shoelace-style/shoelace/dist/components/button/button.js'
import '@shoelace-style/shoelace/dist/components/icon/icon.js'
import '@shoelace-style/shoelace/dist/components/input/input.js'
import '@shoelace-style/shoelace/dist/components/spinner/spinner.js'
import '@shoelace-style/shoelace/dist/components/breadcrumb-item/breadcrumb-item.js'
import '@shoelace-style/shoelace/dist/components/progress-bar/progress-bar.js'
import '@shoelace-style/shoelace/dist/components/breadcrumb/breadcrumb.js'
import '@shoelace-style/shoelace/dist/components/skeleton/skeleton.js'

import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path.js'

// Set the base path to the folder you copied Shoelace's assets to
setBasePath('/public/dist/shoelace')

import ActivityIndicator from 'vue-activity-indicator'
Vue.use(ActivityIndicator)

import VueToast from 'vue-toast-notification'
Vue.use(VueToast, {
  position: 'bottom-right'
})

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

// Example: Load Rails libraries in Vite.
//
// import '@rails/ujs'
//
// import Turbolinks from 'turbolinks'
// import ActiveStorage from '@rails/activestorage'
//
// // Import all channels.
// import.meta.globEager('./**/*_channel.js')
//
// Turbolinks.start()
// ActiveStorage.start()

// Example: Import a stylesheet in app/frontend/index.css
import '@/assets/styles/styles.css'

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    router,
    store,
    created: () => store.dispatch('init'),
    render: (h) => h(App)
  }).$mount()

  document.body.appendChild(app.$el)
})
