
<div class="Dropdown">
  <div class="Dropdown__selected" :class="{ isOpen }" @mousedown="toggle()">
    <slot name="selected" />
  </div>

  <SlideDownTransition>
    <div v-if="isOpen" class="Dropdown__menu" :class="{ isOpen, isOnRight: right }" @click="close()">
      <slot :close="close" name="options" />
    </div>
  </SlideDownTransition>
</div>
