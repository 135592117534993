<template>
  <div class="TextInput" :class="`TextInput--${type}`">
    <Icon class="Icon hidden" name="search" size="4" />
    <input
      class="
        placeholder-gray-400
        block
        flex-1
        w-full
        text-sm
        border-gray-300
        focus:border-primary-500
        rounded-md rounded-none
        appearance-none
        focus:ring-primary-500
      "
      :class="[inputClass, { 'isInvalid': error }]"
      :type="type"
      v-bind="$attrs"
      v-on="listeners"
    />
    <InputErrorMessage class="mt-2" :error="error" />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    type: {
      type: String,
      default: 'text',
      validator: (type) => ['text', 'number', 'password', 'email', 'tel', 'search', 'date'].includes(type)
    },

    error: {
      type: [String, Array]
    },

    inputClass: [String, Array, Object]
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (e) => this.$emit('input', e.target.value)
      }
    }
  }
}
</script>

<style scoped lang="postcss">
.TextInput {
  :disabled {
    @apply bg-gray-100 cursor-not-allowed;
  }

  &--search {
    @apply relative;

    .Icon {
      @apply absolute z-10 block ml-3 opacity-25;
      top: 50%;
      transform: translateY(-50%);
    }

    &:focus-within .Icon {
      @apply opacity-50;
    }

    input {
      padding-left: 2rem;
    }
  }
}
</style>
