<!--
  Icons used from https://heroicons.com.

  Usage:
    <Heroicon name="icon-name"/>  ...  `icon-name` is name used on heroicons.com.

    Heroicons come in two variants - medium and small. We consider medium our default.
    When smaller icons is requested you have to add "-sm" to icon name and create proper
    icon component.

  Examples:
    <Heroicon name="chevron-left"/>
    <Heroicon name="chevron-left-sm"/>
    <Heroicon name="chevronLeft"/>
    <Heroicon name="ChevronLeft"/>
-->
<template>
  <component :is="component" :class="`w-${size} h-${size}`" />
</template>

<script lang="ts">
import Vue from 'vue'

import { resolveGlobImport } from '@/utils/resolveGlobImport'
const icons = import.meta.glob('../icons/*.vue', { eager: true })

export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: '6'
    }
  },

  computed: {
    component() {
      return resolveGlobImport(icons, this.toPascalCase('icon-' + this.name) + '.vue')
    }
  },

  methods: {
    toPascalCase(text: string): string {
      return text.replace(/(^\w|-\w)/g, this.clearAndUpper)
    },

    clearAndUpper(text: string): string {
      return text.replace(/-/, '').toUpperCase()
    }
  }
})
</script>
