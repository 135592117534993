
<div class="TextInput" :class="`TextInput--${type}`">
  <Icon class="Icon hidden" name="search" size="4" />
  <input
    class="
      placeholder-gray-400
      block
      flex-1
      w-full
      text-sm
      border-gray-300
      focus:border-primary-500
      rounded-md rounded-none
      appearance-none
      focus:ring-primary-500
    "
    :class="[inputClass, { 'isInvalid': error }]"
    :type="type"
    v-bind="$attrs"
    v-on="listeners"
  />
  <InputErrorMessage class="mt-2" :error="error" />
</div>
