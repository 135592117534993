import store from '@/store'

export default [
  {
    path: '/',
    component: () => import('@/views/PublicView.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/Public/HomeView.vue'),
        name: 'home'
      },
      {
        path: '/diela',
        component: () => import('@/views/Public/ArtworksView.vue'),
        name: 'artworks'
      },
      {
        path: '/podujatia',
        component: () => import('@/views/Public/EventsView.vue'),
        name: 'events'
      },
      {
        path: '/fakulty',
        component: () => import('@/views/Public/FacultiesView.vue'),
        name: 'faculties'
      },
      {
        path: '/fakulty/:slug',
        component: () => import('@/views/Public/FacultyView.vue'),
        name: 'faculty',
        props: (route) => ({
          slug: String(route.params.slug)
        })
      },
      {
        path: '/katedry/:slug',
        component: () => import('@/views/Public/DepartmentView.vue'),
        name: 'department',
        props: (route) => ({
          slug: String(route.params.slug)
        })
      },
      {
        path: '/autori/:id/:slug',
        component: () => import('@/views/Public/AuthorView.vue'),
        name: 'author',
        props: (route) => ({
          id: String(route.params.id)
        })
      },
      {
        path: '/diela/:id/:slug/',
        component: () => import('@/views/Public/ArtworkView.vue'),
        name: 'artwork',
        props: (route) => ({
          id: String(route.params.id)
        })
      },
      {
        path: '/thesis/:id',
        component: () => import('@/views/Public/ThesisView.vue'),
        name: 'thesis',
        props: (route) => ({
          id: String(route.params.id)
        })
      },
      {
        path: '/podujatia/:id/:slug/',
        component: () => import('@/views/Public/EventView.vue'),
        name: 'event',
        props: (route) => ({
          id: String(route.params.id)
        })
      },
      {
        path: '/autori',
        component: () => import('@/views/Public/AuthorsView.vue'),
        name: 'authors'
      },
      {
        path: '/vyhlasenie-o-pristupnosti',
        component: () => import('@/views/Public/A11yView.vue'),
        name: 'a11y'
      },
      {
        path: '/podmienky-pouzivania',
        component: () => import('@/views/Public/Terms.vue'),
        name: 'terms'
      }
    ]
  },

  {
    path: '/sign-in',
    component: () => import('@/views/Auth/SignInView.vue'),
    name: 'sign-in'
  },
  {
    path: '/magic-link',
    component: () => import('@/views/Auth/VerifySignInView.vue'),
    props: (route) => ({
      token: String(route.query.token)
    })
  },

  {
    path: '/app',
    component: () => import('@/views/Dashboard/DashboardView.vue'),
    name: 'dashboard',
    beforeEnter: async (to, from, next) => {
      const user = store.getters['profile/user']

      // Redirect to homepage unauthorized
      if (!user) {
        return next({ name: 'sign-in' })
      }

      next()
    },
    redirect: {
      name: 'dashboard.profile'
    },
    children: [
      {
        path: '/app/home',
        component: () => import('@/views/Dashboard/DashboardHomeView.vue'),
        name: 'dashboard.home'
      },

      {
        path: '/app/theses',
        component: () => import('@/views/Dashboard/Theses/ThesesView.vue'),
        name: 'dashboard.theses'
      },
      {
        path: '/app/theses/new',
        component: () => import('@/views/Dashboard/Theses/ThesesCreateView.vue'),
        name: 'dashboard.createThesis'
      },
      {
        path: '/app/theses/:id/edit',
        component: () => import('@/views/Dashboard/Theses/ThesesEditView.vue'),
        name: 'dashboard.editThesis',
        props: (route) => ({
          id: String(route.params.id)
        })
      },

      {
        path: '/app/artworks',
        component: () => import('@/views/Dashboard/Artworks/ArtworksView.vue'),
        name: 'dashboard.artworks'
      },

      {
        path: '/app/artworks/new',
        component: () => import('@/views/Dashboard/Artworks/ArtworkCreateView.vue'),
        name: 'dashboard.createArtwork'
      },

      {
        path: '/app/artworks/:id',
        component: () => import('@/views/Dashboard/Artworks/ArtworkDetailView.vue'),
        name: 'dashboard.artworkDetail',
        props: (route) => ({
          id: String(route.params.id)
        })
      },

      {
        path: '/app/artworks/:id/edit',
        component: () => import('@/views/Dashboard/Artworks/ArtworkEditView.vue'),
        name: 'dashboard.editArtwork',
        props: (route) => ({
          id: String(route.params.id)
        })
      },

      {
        path: '/app/profile',
        component: () => import('@/views/Dashboard/Profile/ProfileView.vue'),
        name: 'dashboard.profile'
      },

      {
        path: '/app/profile/edit',
        component: () => import('@/views/Dashboard/Profile/ProfileEditView.vue'),
        name: 'dashboard.editProfile'
      },

      {
        path: '/app/faculties',
        component: () => import('@/views/Dashboard/Faculty/FacultiesView.vue'),
        name: 'dashboard.faculties',
        meta: { requiresAdminAccess: true }
      },

      {
        path: '/app/faculties/:id',
        component: () => import('@/views/Dashboard/Faculty/FacultyView.vue'),
        name: 'dashboard.faculty',
        meta: { requiresAdminAccess: true },
        props: (route) => ({
          facultyId: String(route.params.id)
        }),
        redirect: {
          name: 'dashboard.facultyDetail'
        },
        children: [
          {
            path: '/app/faculties/:id/detail',
            component: () => import('@/views/Dashboard/Faculty/FacultyDetailView.vue'),
            name: 'dashboard.facultyDetail'
          },
          {
            path: '/app/faculties/:id/edit',
            component: () => import('@/views/Dashboard/Faculty/FacultyEditView.vue'),
            name: 'dashboard.editFaculty'
          },
          {
            path: '/app/faculties/:id/departments/new',
            component: () => import('@/views/Dashboard/Faculty/FacultyDepartmentCreateView.vue'),
            name: 'dashboard.createDepartment',
            props: (route) => ({
              departmentId: String(route.params.departmentId)
            })
          },
          {
            path: '/app/faculties/:id/department/:departmentId',
            component: () => import('@/views/Dashboard/Faculty/FacultyDepartmentView.vue'),
            name: 'dashboard.facultyDepartment',
            props: (route) => ({
              departmentId: String(route.params.departmentId)
            })
          },
          {
            path: '/app/faculties/:id/department/:departmentId/edit',
            component: () => import('@/views/Dashboard/Faculty/FacultyDepartmentEditView.vue'),
            name: 'dashboard.editDepartment',
            props: (route) => ({
              departmentId: String(route.params.departmentId)
            })
          },
          {
            path: '/app/faculties/:id/artwork-types/new',
            component: () => import('@/views/Dashboard/Faculty/FacultyArtworkTypeCreateView.vue'),
            name: 'dashboard.createArtworkType',
            props: (route) => ({
              departmentId: String(route.params.departmentId)
            })
          },
          {
            path: '/app/faculties/:id/artwork-types/:artworkTypeId/edit',
            component: () => import('@/views/Dashboard/Faculty/FacultyArtworkTypeEditView.vue'),
            name: 'dashboard.editArtworkType',
            props: (route) => ({
              artworkTypeId: String(route.params.artworkTypeId)
            })
          },
          {
            path: '/app/faculties/:id/department/:departmentId/courses/new',
            component: () => import('@/views/Dashboard/Faculty/FacultyCourseCreateView.vue'),
            name: 'dashboard.createCourse',
            props: (route) => ({
              id: String(route.params.id),
              departmentId: String(route.params.departmentId)
            })
          },
          {
            path: '/app/faculties/:id/department/:departmentId/courses/:courseId/edit',
            component: () => import('@/views/Dashboard/Faculty/FacultyCourseEditView.vue'),
            name: 'dashboard.editCourse',
            props: (route) => ({
              id: String(route.params.id),
              courseId: String(route.params.courseId),
              departmentId: String(route.params.departmentId)
            })
          }
        ]
      },

      {
        path: '/app/users',
        component: () => import('@/views/Dashboard/Users/UsersView.vue'),
        name: 'dashboard.users',
        meta: { requiresAdminAccess: true }
      },
      {
        path: '/app/users/new',
        component: () => import('@/views/Dashboard/Users/UserCreateView.vue'),
        name: 'dashboard.createUser',
        meta: { requiresAdminAccess: true }
      },
      {
        path: '/app/users/bulk',
        component: () => import('@/views/Dashboard/Users/UserBulkCreateView.vue'),
        name: 'dashboard.bulkCreateUsers',
        meta: { requiresAdminAccess: true }
      },
      {
        path: '/app/users/:id',
        component: () => import('@/views/Dashboard/Users/UserDetailView.vue'),
        name: 'dashboard.user',
        props: (route) => ({
          id: String(route.params.id)
        })
      },
      {
        path: '/app/users/:id/edit',
        component: () => import('@/views/Dashboard/Users/UserEditView.vue'),
        name: 'dashboard.editUser',
        meta: { requiresAdminAccess: true },
        props: (route) => ({
          id: String(route.params.id)
        })
      },

      {
        path: '/app/events',
        component: () => import('@/views/Dashboard/Events/EventsView.vue'),
        name: 'dashboard.events'
      },
      {
        path: '/app/events/new',
        component: () => import('@/views/Dashboard/Events/EventCreateView.vue'),
        name: 'dashboard.createEvent'
      },
      {
        path: '/app/events/:id',
        component: () => import('@/views/Dashboard/Events/EventDetailView.vue'),
        name: 'dashboard.event',
        props: (route) => ({
          id: String(route.params.id)
        })
      },
      {
        path: '/app/events/:id/edit',
        component: () => import('@/views/Dashboard/Events/EventEditView.vue'),
        name: 'dashboard.editEvent',
        props: (route) => ({
          id: String(route.params.id)
        })
      }
    ]
  },

  // 404
  {
    path: '/not-found',
    name: 'notFound',
    component: () => import('@/views/NotFoundView.vue')
  },
  {
    path: '*',
    component: () => import('@/views/NotFoundView.vue')
  }
]
