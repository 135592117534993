// JsFromRoutes CacheKey a13ab0dc0405a14972ecc0f11790260e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  listAll: definePathHelper('get', '/api/v1/artworks/all-artworks'),
  search: definePathHelper('get', '/api/v1/artworks/search-artworks'),
  formOptions: definePathHelper('get', '/api/v1/artworks/artwork-form-options'),
  stats: definePathHelper('get', '/api/v1/artworks/stats'),
  list: definePathHelper('get', '/api/v1/artworks'),
  create: definePathHelper('post', '/api/v1/artworks'),
  get: definePathHelper('get', '/api/v1/artworks/:id'),
  update: definePathHelper('patch', '/api/v1/artworks/:id'),
  destroy: definePathHelper('delete', '/api/v1/artworks/:id'),
}
